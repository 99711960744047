import React, {useState, useEffect} from 'react'
import TestPageChild from './TestPageChild'

const TestPage = () => {
  const [testNumber, setTestNumber] = useState(0);

  console.log("[부모, useEffect 상단] 코드 진입");
  // setTestNumber("여기에서 업데이트하면 무한 렌더링");

  useEffect(() => {
    console.log("[부모, useEffect 내부] 코드 실행");
    setTestNumber(1);
  },[])

  console.log(`[부모, useEffect 하단] testNumber: ${testNumber}`);
  
  return (
    <>
    TestPage
    <TestPageChild testNumber={testNumber}/>
    </>
  )
}

export default TestPage