import React, { useEffect } from 'react'

const TestPageChild = ({testNumber}) => {
    console.log(`[자식, useEffect 상단] 코드 진입`);
    console.log(`[자식, useEffect 상단] prop으로 받은 testNumber: ${testNumber}`);
    
    useEffect(() => {
        console.log(`[자식, useEffect 내부] prop으로 받은 testNumber: ${testNumber}`)
    }, [])

    console.log(`[자식, useEffect 하단] prop으로 받은 testNumber: ${testNumber}`);
    
    return (
        <div>
            TestPageChild
        </div>
    )
}

export default TestPageChild