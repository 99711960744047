module.exports = {
  scale: {
    C2: "C2.wav",
    "C#2": "Cs2.wav",
    D2: "D2.wav",
    "D#2": "Ds2.wav",
    E2: "E2.wav",
    F2: "F2.wav",
    "F#2": "Fs2.wav",
    G2: "G2.wav",
    "G#2": "Gs2.wav",
    A2: "A2.wav",
    "A#2": "As2.wav",
    B2: "B2.wav",
    C3: "C3.wav",
    "C#3": "Cs3.wav",
    D3: "D3.wav",
    "D#3": "Ds3.wav",
    E3: "E3.wav",
    F3: "F3.wav",
    "F#3": "Fs3.wav",
    G3: "G3.wav",
    "G#3": "Gs3.wav",
    A3: "A3.wav",
    "A#3": "As3.wav",
    B3: "B3.wav",
    C4: "C4.wav",
    "C#4": "Cs4.wav",
    D4: "D4.wav",
    "D#4": "Ds4.wav",
    E4: "E4.wav",
    F4: "F4.wav",
    "F#4": "Fs4.wav",
    G4: "G4.wav",
    "G#4": "Gs4.wav",
    A4: "A4.wav",
    "A#4": "As4.wav",
    B4: "B4.wav",
  },
  availableNotes: [
    "C2",
    "C#2",
    "D2",
    "D#2",
    "E2",
    "F2",
    "F#2",
    "G2",
    "G#2",
    "A2",
    "A#2",
    "B2",
    "C3",
    "C#3",
    "D3",
    "D#3",
    "E3",
    "F3",
    "F#3",
    "G3",
    "G#3",
    "A3",
    "A#3",
    "B3",
    "C4",
    "C#4",
    "D4",
    "D#4",
    "E4",
    "F4",
    "F#4",
    "G4",
    "G#4",
    "A4",
    "A#4",
    "B4",
  ].reverse(),
  drumScale: {
    D2: "D2.wav",
    E2: "E2.wav",
  },
  availableDrumNotes: ["E2", "D2"],
};
